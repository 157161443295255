.list_card{
    border: 2px solid #f5f5f5;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
    border-radius: 10px;
    padding: 30px;
   margin: 20px auto;
   height: 450px;
}

.list_card_content_title{
    font-family: gustavo;
    font-size: min(max(17px, calc(1.0625rem + (28 - 17) * ((100vw - 320px) / (1400 - 320)))), 28px);
}


.list_card_content_subtitle{
    font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
    font-family: Inter, 'sans-serif';
    color: #131313;
    margin-top: 10px;
    font-weight: 500;


}
.list_card_content ul{
    margin-top: 20px;
}


.list_card_content ul li{
    font-family: Inter, 'sans-serif';
    font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
    color: #666666;
    margin-top: 20px;
    font-weight: 400;
}

@media screen and (max-width:991px) {

    .list_card {

        height: 345px;

    }
    
}