.hero_section{
    height: 680px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #00040e;



}
.video_section{
    position: absolute;
  width: 100%;
  height: 100%;
    right:  0;
}
.video_section video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.hero_section_inner_section{
    height: 100%;
    width: 100%;
}
.lines{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
   
}
.line{


  position: absolute;
  width: 1.4px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(0, 0, 0, 0.064);
  overflow: hidden;

}
.line::after{
    content: '';
    display: block;
    position: absolute;
    height: 30vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,188,58,1) 35%);
    animation: drop 4s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.line:nth-child(1):after{
   background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,255,149,1) 35%);
    
} 
.line:nth-child(1){
    margin-left: -8%;
    
} 
.line:nth-child(1)::after{
    animation-delay: 1s
}
.line:nth-child(3){
    margin-left: 8%
}
.line:nth-child(3)::after{
    animation-delay: 1s
} 
.line:nth-child(3):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,172,255,1) 35%);;
     
 } 
.line:nth-child(4){
    margin-left: 16%
}
.line:nth-child(4)::after{
    animation-delay: 1.2s
} 
.line:nth-child(4):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,172,255,1) 35%);;
} 
.line:nth-child(5){
    margin-left: 24%
}
.line:nth-child(5)::after{
    animation-delay: 1.4s
} 
.line:nth-child(5):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,255,149,1) 35%);
}
.line:nth-child(6){
    margin-left: -16%
}
.line:nth-child(6)::after{
    animation-delay: 1.3s
} 
.line:nth-child(6):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,255,149,1) 35%);
} 
.line:nth-child(7){
    margin-left: -24%
}
.line:nth-child(7)::after{
    animation-delay: 1.7s
} 
.line:nth-child(7):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,172,255,1) 35%);;
}
.line:nth-child(8){
    margin-left: 32%
}
.line:nth-child(8)::after{
    animation-delay: 1.8s
} 
.line:nth-child(8):after{
   background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(204,58,255,1) 35%);
} 
.line:nth-child(9){
    margin-left: 40%
}
.line:nth-child(9)::after{
    animation-delay: 1.1s
} 
.line:nth-child(9):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(204,58,255,1) 35%);
     
}
.line:nth-child(10){
    margin-left: -32%
}
.line:nth-child(10)::after{
    animation-delay: 1.2s
} 
.line:nth-child(10):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(204,58,255,1) 35%);
} 
.line:nth-child(11){
    margin-left: -40%
}
.line:nth-child(11)::after{
    animation-delay: 1.4s
} 
.line:nth-child(11):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,255,149,1) 35%);
}
.line:nth-child(12){
    margin-left: 48%
}
.line:nth-child(12)::after{
    animation-delay: 1.6s
} 
.line:nth-child(12):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(204,58,255,1) 35%);
}
.line:nth-child(13){
    margin-left: -48%
}
.line:nth-child(13)::after{
    animation-delay: 1.2s
} 
.line:nth-child(13):after{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(58,172,255,1) 35%);;
}
.hero_section_inner_section{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero_section_inner_section_details h1{
    font-family: gustavo;
    font-size: min(max(20px, calc(1.25rem + (74 - 20) * ((100vw - 320px) / (1400 - 320)))), 74px);
 color: #fff;
    margin-top: 50px;
    line-height: 1.1;
    
}
.hero_section_inner_section_details_sub{
    font-family: 'Inter', sans-serif;
    font-size: min(max(13px, calc(0.8125rem + (18 - 13) * ((100vw - 320px) / (1400 - 320)))), 18px);
    font-weight: 400;
    color: #999999;
 
    margin-top: 30px;

}
.hero_section_btn{
    margin-top: 28px;
    position: relative;
}

.btn_shadow{
    width: 292px;
    margin: auto;
    position: relative;
}
.shadow_1{
    width: 292px;
    height: 63px;
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
    position: absolute;
    top: 10px;
    left: 5px;
    background: #333;
}
.polygon_btn_cont{

    width: 292px;
    height: 63px;
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
    position: relative;
    background: #333;
    box-sizing: border-box;
}
.polygon_btn{
    width: 284px;
    height: 57px;
    top: 3px;
    left: 4px;
    position: absolute;
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
    border: 1px solid #333 !important;
    background: #fff !important;
   
    color: #333 !important;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    border-radius: 0px 0px 0px 0px;
    
   
}
.relative{
    position: relative;
}
.heade_block{

    position: relative;
    height: 100%;
    width: 100%;
}

.block_start{

    height: 110%;
    width: 0%;
    position: absolute;
    background-color: #333;
    top: 0;
    right:  0;
   opacity: 0;
    animation: fade 1.1s ;
    animation-fill-mode: backwards;
    z-index: 8;
}
.start1{
    animation: fade 1.5s  !important;
    height: 130%;
}
.btn_shadow{
transition: top 0.4s ease-in-out  !important;
}
.shadow_1{
    transition: top 0.3s ease-in-out !important;
}
.btn_shadow:hover .shadow_1{
    top: 0px;
}

/****************** Responsive   *****************/

@media screen and (max-width:991px) {
    .hero_section_inner_section_details_sub{
       margin-top: 10px;
    }
    .video_section{
        width: 100%;
    }
 }
@media screen and (max-width: 800px) {
    .hero_section{
        height: 450px !important;
    }
    .hero_section_btn{
        margin-top: 0px;
        margin-bottom: 15px;
      }
}
@media screen and (max-width: 540px) {
    .hero_section{
        height: 375px !important;
    }
    .hero_section_btn::after{
        bottom: -10px !important;

    }
    .hero_section_btn svg{
        height: 16px;
        width: 16px;
    }
    .hero_section_btn span {
      
        margin-left: 10px;
    }

}
@media screen and (max-width :540px){
    .line::after{
height: 6vh !important;
    }
    .btn_shadow {
      width: 200px;
  }
  .hero_section_btn{
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .shadow_1 {
    width: 200px;
    height: 58px;
    top: 3px !important;
    left: 13px;
    
  }
  .polygon_btn_cont {
    width: 210px;
    height: 57px;
  }
  .polygon_btn {
    width: 200px;
    height: 50px;
    top: 3px;
    left: 4px;
    font-size: 16px;
  
  }
}
.hero_section_btn a{
    color:  #fff !important;
    padding-bottom: 10px;
    text-decoration: none;
    /* border-bottom: 1px solid #fff ; */
    font-family: 'Inter';
    font-weight: 600;
    font-size: calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)));
     
}
.hero_section_btn span{
    font-size: 20px;
    color: #fff;
    margin-left: 20px;

}
.hero_section_btn{
    position: relative;
    display: inline-block;

}
.hero_section_btn::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 80%;
    bottom: -10px;
    left: 0;
    background-color: #fff;
    transition: 0.4s all ease;

}



/* modal */

.modal-header{
    border-bottom: none !important;
    padding: 20px 30px 0px 30px !important;
}

.modal-body{
    padding: 20px 30px 0 30px !important;
}

.modal-title{
    font-size: min(max(16px, calc(1rem + (22 - 16) * ((100vw - 320px) / (1400 - 320)))), 22px);

    font-family: gustavo;
}

.modal-footer{
    border-top: none !important;
    margin-bottom: 30px !important;
}

.shadow_1 {
    width: 200px;
    height: 50px;
    top: 14px !important;
    left: 52px;
    
  }
  .polygon_btn_cont {
    width: 210px;
    height: 57px;
  }
  .polygon_btn {
    width: 200px;
    height: 50px;
    top: 3px;
    left: 4px;
    font-size: 16px;
  
  }

  .shadow_1{
    transition: top 0.3s ease-in-out;
}

.btn_shadow:hover .shadow_1{
    top: 0px !important;
    transition: top 0.3s ease-in-out;

}

@media screen and (max-width: 540px) {
    .shadow_1{
        left: 10px !important;
    }
}


/****************** Animations *****************/

  @keyframes drop{
    0%{
        top: -50%
    }
   
  100%{
    top: 110%
  }
   

}
.cursor-pointer{
    cursor: pointer;
}
.hero_section_btn:hover::after{
width: 100%;
}