.mobile_web_sections_title{
    font-family: gustavo;
    font-size: min(max(20px, calc(1.25rem + (38 - 20) * ((100vw - 320px) / (1400 - 320)))), 38px);
    margin-bottom: 20px;
}

.mobile_web_sections{
    margin-top: 75px;
}
.mobile_web_sections .simple_card {
    height: 191px ;
}
.mobile_web_section{
    margin-bottom: 40px;
}

@media screen and (max-width: 991px) {

    .mobile_web_sections{
        margin-top: 20px;
    }
    .mobile_web_sections .simple_card {
        height: 100%;
    }
    
}