.simple_card{
    border: 2px solid #f5f5f5;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
    border-radius: 10px;
    padding: 32px;
   margin: 13px auto !important;
   height: 285px;
   box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
}

.simple_card_content_title{
    font-family: gustavo;
    font-size: min(max(17px, calc(1.0625rem + (28 - 17) * ((100vw - 320px) / (1400 - 320)))), 28px);
}

.simple_card_content p{
    font-family: Inter, 'sans-serif';
    font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
    color: #666666;
    margin-top: 10px;
    font-weight: 400;

}

@media screen and (max-width:1400px) {
.simple_card{
    height: 100% !important;
}


    
}