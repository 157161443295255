.file_upload_job{
    width: 320px;
    height: 76px;
    border-radius: 12px;
    background-color: #fff;
    
    text-align: left;
    padding: 15px;
    border: 2px solid #f5f5f5;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
   

}
.ml-2{
    margin-left: 20px;
}
.btn-flex{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}
.btn-flex h6{
    margin-bottom: 0;
    font-family: Inter ;
    font-size: 17px;
    font-weight: 700;
    color: #7D7F82;
}
.btn-flex p{
    margin-bottom: 0;
    font-family: Inter ;
    font-size: 12px;
    font-weight: 500;
    color: #777777;
}
.required{
    color: red;
}
.form-label {
    font-family: Inter ;
    font-size: 16px;
    font-weight: 700;
}
.apply{
    background-color: #3A65FF;
}

@media screen and (max-width:991px){
    .margin_form{
        margin-bottom: 18px !important;
    }
   
}
@media screen and (max-width:991px){
    .file_upload_job{
        width: 244px;
    }
    .btn-flex h6{
font-size: 15px;
    }
}