.banner-section{
    /* background-image: url('./../../assets/images/unified_img.png'); */
    height: 400px;
    
    /* padding-right: 55px; */
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.page-title{
    font-size: min(max(20px, calc(1.25rem + (64 - 20) * ((100vw - 320px) / (1400 - 320)))), 64px);
    color: white;
    font-weight: 800;
    margin-top: 40px;

    font-family: gustavo;
}

@media screen and (max-width:800px) {
    .banner-section {
        height: 220px;
        background-size: cover !important;
        background-position:top center !important;
        margin-top: 60px;
     }
    
}