.icon-card{
    border-radius: 10px;
    padding: 20px;
   margin: 20px auto;
   height: 285px;
   box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
   
}

.item-title{
    font-weight: 600;
    font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
    margin-top: 15px;
    font-family: 'gustavo';
}
.item-content{
    font-weight: 400;
    font-size: min(max(13px, calc(0.8125rem + (16 - 13) * ((100vw - 320px) / (1400 - 320)))), 16px);
    margin-top: 15px;
    font-family: 'Inter';
}
.content_margin{
    margin-top: 10px !important;
}
@media (max-width: 1024px){
    .icon-card{
        height: 250px !important;
    }
}
@media (max-width: 590px){
    .icon-card{
        height: 200px !important;
    }
}