.careers_section{
    margin: 50px auto;
}
.reset_btn{
    color:black;
    width:120px;
    height: 46px;
    text-align: center;
    border: 1px solid black;
    border-radius: 0px;
    display: flex;
    align-items: center;
}
.submit_btn{
    color:white;
    width:120px;
    height: 46px;
    text-align: center;
    border: 1px solid #5259FF;
    border-radius: 0px;
    background-color: #5259FF;
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.select_field{
    background: #F8F8F8;
    border-radius: 0px;
    border: none;
}
.button_section{
   display: flex;
   justify-content: flex-end;
}
.card{
    border-radius: 8px;
    box-shadow: 1px 3px 15px 2px rgb(216 216 216 / 26%);
    margin-bottom: 30px;
    padding:20px;
}
@media (max-width: 768px){
    .card .row .col-md-3{
        margin-bottom: 10px;
    }
}
@media (max-width: 912px){
    .button_section button{
        height: 34px;
        margin-top: 8px;
    }
 
}
@media (max-width: 361px){
    .select_field{
        font-size: 14px;
    padding: 8px !important;
    }
    .button_section{
       justify-content: flex-start;
    }
}